:root {
  --color-green: #57D9A3;
  --color-green-15: rgba(87, 217, 163, 0.15);
  --color-green-dark: #4A7B70;
  --color-blue: #5a78ff;
  --color-blue-15: rgba(90, 120, 255, 0.15);
  --color-blue-25: rgba(90, 120, 255, 0.25);
  --color-blue-dark: #5a78ff;
  --color-blue-dark-05: rgba(38, 56, 134, 0.05);
  --color-blue-dark-06: rgba(38, 56, 134, 0.06);
  --color-blue-dark-10: rgba(38, 56, 134, 0.10);
  --color-blue-dark-15: rgba(38, 56, 134, 0.15);
  --color-blue-dark-20: rgba(38, 56, 134, 0.20);
  --color-blue-dark-30: rgba(38, 56, 134, 0.30);
  --color-blue-dark-40: rgba(38, 56, 134, 0.4);
  --color-blue-dark-hover: #182352;
  --color-blue-dark-hover-05: rgba(24, 35, 82, 0.05);
  --color-black: #212121;
  --color-black-04: rgba(33, 33, 33, 0.04);
  --color-black-05: rgba(33, 33, 33, 0.05);
  --color-black-06: rgba(33, 33, 33, 0.06);
  --color-black-10: rgba(33, 33, 33, 0.10);
  --color-black-15: rgba(33, 33, 33, 0.15);
  --color-black-20: rgba(33, 33, 33, 0.2);
  --color-black-30: rgba(33, 33, 33, 0.3);
  --color-black-35: rgba(33, 33, 33, 0.35);
  --color-black-70: rgba(33, 33, 33, 0.7);
  --color-black-85: rgba(33, 33, 33, 0.85);
  --color-status-error: #FD4545;
  --color-status-error-05: rgba(253, 69, 69, 0.05);
  --color-status-error-10: rgba(253, 69, 69, 0.1);
  --color-status-error-30: rgba(253, 69, 69, 0.3);
  --color-status-error-20: rgba(253, 69, 69, 0.2);
  --color-status-warning: #E5981D;
  --color-status-success: #39C639;
  --color-status-success-05: rgba(57, 198, 57, 0.05);
  --color-status-success-10: rgba(57, 198, 57, 0.1);
  --color-status-success-20: rgba(57, 198, 57, 0.2);
  --color-status-success-30: rgba(57, 198, 57, 0.3);
  
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  --z-1: 100;
  --z-2: 200;
  --z-3: 300;
  --z-4: 400;
  --z-5: 500;
  --z-6: 600;
  --z-7: 700;
  --z-8: 800;
  --z-9: 900;
  --z-10: 1000;
}
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: none;
  border-radius: 3px;
  color: #fff;
  background: var(--color-blue-dark);
  min-width: 100px;
  height: 34px;
  font-family: inherit;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  padding: 8px 20px;
  transition: background 0.2s linear;
}

.btn--img img {
  margin-left: 10px;
  margin-top: -1px;
}

.btn:hover,
.btn:focus {
  background: var(--color-blue-dark-hover);
}

.btn.btn--outline {
  background: rgba(255, 255, 255, 0);
  border: 1px solid var(--color-blue-dark-20);
  color: var(--color-blue-dark);
}

.btn.btn--outline:hover,
.btn.btn--outline:focus {
  background: var(--color-blue-dark-06);
}

.btn.btn--icon {
  min-width: 0;
  min-height: 0;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
}

.btn.btn--success {
  background: var(--color-status-success);
}

.btn.btn--error {
  background: var(--color-status-error);
}

.btn--icon {
  margin-right: 10px;
}
.field {
  height: 34px;
  padding: 5px 10px;
  font-size: 1.2rem;
  font-family: inherit;
  background: #fff;
  border: 1px solid var(--color-blue-dark);
  border-radius: 3px;
  transition: all 0.2s linear;
}

.field.field--grey {
  border-color: #BCBCBC;
}

.field.field--login {
  display: block;
  width: 100%;
  height: 42px;
  background: var(--color-blue-dark-06)
}

.field.field--login:focus {
  background: var(--color-blue-dark-15)
}

.field:focus {
  border-color: var(--color-blue-dark-hover);
  background: var(--color-blue-dark-hover-05);
}

.field-wrapper {
  position: relative;
}

.field-wrapper .field {
  width: 100%;
}

.field-wrapper .btn--icon {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 4px;
  margin-right: 4px;
  background: none;
  border: none;
}

.field-wrapper .btn--icon:hover {
  background: var(--color-blue-15);
}